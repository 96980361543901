import { defineStore } from "pinia";

import moment from "moment";

import { APIEvents } from "@/api/events";
import { APIAgendas } from "@/api/agendas.js";

import { useSearchStore } from "@/stores/search.js";
import { useEventTypesStore } from "@/stores/models-eventTypes.js";
import { useAgendasStore } from "@/stores/models-agendas.js";
import { useEventGroupsStore } from "@/stores/models-eventGroups.js";
import { useUnavailabilitiesStore } from "@/stores/models-unavailabilities.js";

import * as Sentry from "@sentry/vue";
import { timeFilterConditionCheck } from "@/utils/dates";

export const useEventsStore = defineStore("events", {
  state: () => {
    return {
      calendarCurrentDate: moment(),
      firstDate: null,
      lastDate: null,
      searchMode: false,
      activeSearchTerm: "",
      listFilter: "now",
      isSearchInputFocused: false,
      totalItems: 0,
      displayedItems: 0,
      itemsPerPage: 10,
      page: 1,
      isCalendarEventsAccessForbidden: false,
      plannerEventObjectsNames: [],
      plannerCompanyName: "",
      currentAgendaId: localStorage.getItem("current-agenda-id")
        ? Number(localStorage.getItem("current-agenda-id"))
        : null,
      temporaryAgendaId: localStorage.getItem("temporary-agenda-id")
        ? Number(localStorage.getItem("temporary-agenda-id"))
        : null,
      multipleAgendaFetchCalled: false,

      data: [],
    };
  },
  getters: {
    /**
     * NOTE: By default, get events that are not in groups..
     */
    getAll(state) {
      return state.data.filter(event => !event.eventGroupId).map(item => ({ ...item }));
    },
    // ???: dateStart/dateEnd moment local? dates are in utc in store ... ??
    getMultipleForCalendar: state => (date, viewType, agendaId) => {
      let dateStart = null;
      let dateEnd = null;
      if (viewType == "dayGridMonth") {
        dateStart = moment(date).startOf("month").subtract(15, "days").local();
        dateEnd = moment(date).endOf("month").add(15, "days").local();
      } else if (viewType == "timeGridWeek") {
        dateStart = moment(date).startOf("week").local();
        dateEnd = moment(date).endOf("week").local();
      } else if (viewType == "timeGridDay") {
        dateStart = moment(date).startOf("day").local();
        dateEnd = moment(date).endOf("day").local();
      }

      const eventTypesStore = useEventTypesStore();

      return Object.values(state.data)
        .filter(event => {
          // ...
          const isSameAgenda = agendaId ? agendaId === event.agendaId : true;
          return (
            isSameAgenda &&
            !event.eventGroupId &&
            moment(event.dateStart).isBefore(dateEnd) &&
            moment(event.dateEnd).isAfter(dateStart)
          );
        })

        .map(event => ({
          ...event,
          eventObject: event.eventObject,
          // !!!: Maybe event.eventType should be used here
          // eventObject: eventObjectsStore.get(event.eventObjectId),
          eventType: eventTypesStore.get(event.eventTypeId),
        }));
    },
    // ???: dateStart/dateEnd params are in local time .. dates are in utc in store ... ??
    getMultipleForList:
      state =>
      (timeFilter, agendaId, order = "desc") => {
        const eventTypesStore = useEventTypesStore();
        const agendasStore = useAgendasStore();

        return (
          state.data
            .filter(event => {
              const isSameAgenda = agendaId ? agendaId === event.agendaId : true;
              return (
                isSameAgenda && !event.eventGroupId && timeFilterConditionCheck(timeFilter, event)
              );
            })
            // NOTE: Default order descending by dateStart
            .sort((a, b) => {
              if (order == "desc") {
                return a.dateStart < b.dateStart ? 1 : -1;
              } else {
                return a.dateStart > b.dateStart ? 1 : -1;
              }
            })
            .map(event => ({
              ...event,
              eventType: eventTypesStore.get(event.eventTypeId),
              agenda: agendasStore.get(event.agendaId),
            }))
        );
      },
    getByAgendaId: state => agendaId => {
      const eventTypesStore = useEventTypesStore();

      return (
        Object.values(state.data)
          .filter(event => {
            return !event.eventGroupId && event.agendaId == agendaId;
          })
          // NOTE: Default order ascending by dateStart
          .sort((a, b) => {
            return a.dateStart > b.dateStart ? 1 : -1;
          })
          .map(event => ({
            ...event,
            // eventObject: eventObjectsStore.get(event.eventObjectId),
            eventType: eventTypesStore.get(event.eventTypeId),
          }))
      );
    },
    get: state => id => state.data.find(event => event.id == id),
    getOneForCalendar: state => id => {
      const eventTypesStore = useEventTypesStore();
      return state.data
        .filter(event => {
          return event.id == id;
        })
        .map(event => ({
          ...event,
          eventType: eventTypesStore.get(event.eventTypeId),
        }))[0];
    },

    isPagerNeeded(state) {
      return state.totalItems > state.displayedItems;
    },
    getIsCalendarEventsAccessForbidden(state) {
      return state.isCalendarEventsAccessForbidden;
    },
    getPlannerEventObjectsNames(state) {
      return state.plannerEventObjectsNames;
    },
    getPlannerCompanyName(state) {
      return state.plannerCompanyName;
    },
    getMultipleAgendaFetchCalled(state) {
      return state.multipleAgendaFetchCalled;
    },
    getActiveSearchTerm: state => {
      return state.activeSearchTerm;
    },
  },
  actions: {
    fetchForCalendar({ date, authToken = null, agendaChanged = false }) {
      let dateStart = moment(date).startOf("month").subtract(2, "months").local();
      let dateEnd = moment(date).endOf("month").add(2, "months").local();
      const newFirstDate = moment(dateStart);
      const newLastDate = moment(dateEnd);
      // let dateStartToDelete = null;
      // let dateEndToDelete = null;
      let fetch = true;
      if (this.firstDate && this.lastDate) {
        fetch = false;
        if (dateStart.isBefore(this.firstDate)) {
          // dateStartToDelete = moment(dateEnd).add(1, "months").startOf("month").local();
          // dateEndToDelete = moment(this.lastDate).endOf("month").local();
          if (dateEnd.isAfter(moment(this.firstDate))) {
            dateEnd = moment(this.firstDate).subtract(1, "months").endOf("month").local();
          }
          fetch = true;
        }
        if (dateEnd.isAfter(this.lastDate)) {
          // dateStartToDelete = moment(this.firstDate).startOf("month").local();
          // dateEndToDelete = moment(dateStart).subtract(1, "months").endOf("month").local();
          if (dateStart.isBefore(moment(this.lastDate))) {
            dateStart = moment(this.lastDate).add(1, "months").startOf("month").local();
          }
          fetch = true;
        }
      }
      // NOTE: Before FETCH
      // Force fetch when agenda id was changed on multiple agenda
      if (agendaChanged) {
        fetch = true;
      }
      // NOTE: FETCH Data
      if (fetch && !authToken) {
        // !!!: SENTRY
        if (import.meta.env.PROD) {
          Sentry.setUser({
            email: localStorage.getItem("user-email"),
          });

          Sentry.setContext("company", {
            id: localStorage.getItem("company-id"),
          });

          Sentry.setContext("jwt", {
            token: localStorage.getItem("access-token"),
          });
        }

        // ...
        APIEvents.getAll(
          null,
          // ???: timezones?
          dateStart.format("YYYY-MM-DD 00:00:00"),
          null,
          dateEnd.format("YYYY-MM-DD 23:59:59"),
          this.currentAgendaId,
          false
        ).then(res => {
          const searchStore = useSearchStore();
          // do this commit only when is not in seach mode
          if (!searchStore.calendarSearchMode) {
            res.data.events.forEach(item => {
              this.insertOrUpdate(item);
            });
            // if (dateStartToDelete && dateEndToDelete) {
            //   // ???: do something to delete event objects too?
            //   this.delete(event => {
            //     return (
            //       moment(event.dateStart).isBefore(dateEndToDelete) &&
            //       moment(event.dateEnd).isAfter(dateStartToDelete)
            //     );
            //   });
            // }
            this.firstDate = newFirstDate;
            this.lastDate = newLastDate;

            if (res.data.eventGroups) {
              const eventGroupsStore = useEventGroupsStore();
              for (const eventGroup of res.data.eventGroups) {
                eventGroupsStore.insertOrUpdate(eventGroup);
              }
            }
            if (res.data.unavailabilities) {
              const unavailabilitiesStore = useUnavailabilitiesStore();
              for (const unavailability of res.data.unavailabilities) {
                unavailabilitiesStore.insertOrUpdate(unavailability);
              }
            }
          }
        });
      } else if (fetch && authToken) {
        APIAgendas.getPlannerEvents(
          authToken,
          dateStart.format("YYYY-MM-DD 00:00:00"),
          dateEnd.format("YYYY-MM-DD 23:59:59"),
          false
        )
          .then(res => {
            const searchStore = useSearchStore();
            // do this commit only when is not in search mode
            if (!searchStore.calendarSearchMode) {
              res.data.items.forEach(item => {
                this.insertOrUpdate(item);
              });
              // if (dateStartToDelete && dateEndToDelete) {
              //   // ???:
              //   // Event.delete(event => {
              //   //   return (
              //   //     moment(event.dateStart).isBefore(dateEndToDelete) &&
              //   //     moment(event.dateEnd).isAfter(dateStartToDelete)
              //   //   );
              //   // });
              // }
              this.isCalendarEventsAccessForbidden = false;
              this.firstDate = newFirstDate;
              this.lastDate = newLastDate;
              this.plannerEventObjectsNames = res.data.eventObjectNames;
              this.plannerCompanyName = res.data.companyName;
            }
          })
          .catch(() => {
            this.isCalendarEventsAccessForbidden = true;
          });
      }
    },
    fetchForList({
      searchInput,
      dateStartAfter,
      dateStartBefore,
      dateEnd,
      pagination,
      pageNr,
      order,
      timeFilter,
    }) {
      // !!!: SENTRY
      if (import.meta.env.PROD) {
        Sentry.setUser({
          email: localStorage.getItem("user-email"),
        });

        Sentry.setContext("company", {
          id: localStorage.getItem("company-id"),
        });

        Sentry.setContext("jwt", {
          token: localStorage.getItem("access-token"),
        });
      }

      // ...
      APIEvents.getAll(
        searchInput,
        dateStartAfter,
        dateStartBefore,
        dateEnd,
        this.currentAgendaId,
        pagination,
        pageNr,
        undefined,
        order,
        timeFilter
      ).then(res => {
        res.data.events.forEach(event => {
          this.insertOrUpdate(event);
        });

        this.totalItems = res.data.totalItems;
        this.displayedItems = pageNr * this.itemsPerPage;
        this.page = pageNr;

        if (res.data.eventGroups) {
          const eventGroupsStore = useEventGroupsStore();
          for (const eventGroup of res.data.eventGroups) {
            eventGroupsStore.insertOrUpdate(eventGroup);
          }
        }
        if (res.data.unavailabilities) {
          const unavailabilitiesStore = useUnavailabilitiesStore();
          for (const unavailability of res.data.unavailabilities) {
            unavailabilitiesStore.insertOrUpdate(unavailability);
          }
        }
      });
    },
    apiGet(id) {
      return APIEvents.get(id).then(res => {
        this.insertOrUpdate(res.data);
      });
    },
    create(payload) {
      return new Promise((resolve, reject) => {
        APIEvents.post(payload)
          .then(res => {
            if (res.data.event) {
              this.insertOrUpdate(res.data.event);
            }
            if (res.data.eventGroup) {
              const eventGroupsStore = useEventGroupsStore();
              eventGroupsStore.insertOrUpdate(res.data.eventGroup);
            }
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    update(payload) {
      return APIEvents.put(payload.id, {
        event: payload.event,
        notifications: payload.notifications,
      }).then(res => {
        if (res.data.event) {
          this.insertOrUpdate(res.data.event);
        }
        if (res.data.eventGroups && res.data.eventGroups.length > 0) {
          const eventGroupsStore = useEventGroupsStore();
          for (const eventGroup of res.data.eventGroups) {
            eventGroupsStore.insertOrUpdate(eventGroup);
          }
        }
      });
    },
    remove(payload) {
      return APIEvents.delete(payload).then(res => {
        this.delete(payload.id);

        const eventGroupsStore = useEventGroupsStore();
        if (res.data.eventGroupId && res.data.eventGroup) {
          eventGroupsStore.insertOrUpdate(res.data.eventGroup);
        } else if (res.data.eventGroupId && !res.data.eventGroup) {
          eventGroupsStore.delete(res.data.eventGroupId);
        }
      });
    },

    insertOrUpdate(object) {
      if (!object) return;

      const index = this.data.findIndex(item => item.id == object.id);
      if (index != "-1") {
        this.data[index] = object;
      } else {
        this.data.push(object);
      }
    },
    delete(objectId) {
      const index = this.data.findIndex(item => item.id == objectId);
      if (index != "-1") {
        this.data.splice(index, 1);
      }
    },
    duplicate(payload) {
      return new Promise((resolve, reject) => {
        APIEvents.duplicate(payload.eventId, payload.data)
          .then(res => {
            // NOTE: Add event in store only when is not in a eventGroup
            if (res.data.event) {
              this.data.push(res.data.event);
            }
            // NOTE: Insert or update EventGroup
            // if (res.data.eventGroup) {
            //   const eventGroupsStore = useEventGroupsStore();
            //   eventGroupsStore.insertOrUpdate(res.data.eventGroup);
            // }
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    performSearch(activeSearchTerm) {
      this.activeSearchTerm = activeSearchTerm;
      this.searchMode = true;
    },
    resetSearch() {
      this.activeSearchTerm = "";
      this.searchMode = false;
    },
    resetCalendarData() {
      this.firstDate = null;
      this.lastDate = null;
    },
    setActiveSearchTerm(state, activeSearchTerm) {
      state.activeSearchTerm = activeSearchTerm;
    },
    setSearchInputFocused(state, value) {
      state.isSearchInputFocused = value;
    },
    setCurrentAgendaId(agendaId) {
      this.currentAgendaId = agendaId;

      if (agendaId === null) {
        localStorage.removeItem("current-agenda-id");
      } else {
        localStorage.setItem("current-agenda-id", agendaId);
      }

      // NOTE: reset temporary agenda id when setting current agenda id
      this.setTemporaryAgendaId(null);
    },
    setTemporaryAgendaId(agendaId) {
      this.temporaryAgendaId = agendaId;

      if (agendaId === null) {
        localStorage.removeItem("temporary-agenda-id");
      } else {
        localStorage.setItem("temporary-agenda-id", agendaId);
      }
    },
    setCalendarCurrentDate(date, { saveInLocalStorage = true } = {}) {
      // date is moment..
      this.calendarCurrentDate = date;

      if (saveInLocalStorage)
        localStorage.setItem("agenda-selected-calendar-current-date", date.format("YYYY-MM-DD"));
    },
    setCalendarListFilter(listFilter, { saveInLocalStorage = true } = {}) {
      this.listFilter = listFilter;

      if (saveInLocalStorage)
        localStorage.setItem("agenda-selected-calendar-list-filter", listFilter);
    },
    setMultipleAgendaFetchCalled(state) {
      state.multipleAgendaFetchCalled = !state.multipleAgendaFetchCalled;
    },
    changePaymentStatus(id, agendaId, paymentStatus, paymentDate) {
      return APIEvents.changePaymentStatus(id, { paymentStatus, paymentDate, agendaId }).then(
        res => {
          this.insertOrUpdate(res.data);
        }
      );
    },
    removeEventType(eventId, eventTypeId) {
      return new Promise((resolve, reject) => {
        APIEvents.removeEventType(eventId, eventTypeId)
          .then(res => {
            const eventIndex = this.data.findIndex(event => event.id == eventId);
            const eventTypeIndex = this.data[eventIndex]?.eventTypes
              ? this.data[eventIndex].eventTypes.findIndex(item => item.id == eventTypeId)
              : undefined;

            if (eventTypeIndex && eventTypeIndex != "-1") {
              this.data[eventIndex].eventTypes.splice(eventTypeIndex, 1);
            }
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
  },
});
