import { defineStore } from "pinia";

import { APISubscriptionPlans } from "@/api/subscription-plans.js";

export const useSubscriptionPlansStore = defineStore("subscriptionPlans", {
  state: () => {
    return { data: [] };
  },
  getters: {
    get: state => {
      return Object.values(state.data)
        .sort((a, b) => {
          // order ascending
          return a.order > b.order ? 1 : -1;
        })
        .map(item => ({ ...item }));
    },
    getStandard: state => {
      return Object.values(state.data)
        .filter(item => ["Free", "Basic", "Business", "Premium"].includes(item.name))
        .sort((a, b) => (a.price > b.price ? 1 : -1));
    },
    getById: state => id => {
      return state.data.find(item => item.id == id);
    },
    getPricePerYearWithDiscount: state => id => {
      const subscriptionPlan = state.data.find(item => item.id == id);
      let price = Number(subscriptionPlan.price);
      const yearDiscount = Number(subscriptionPlan.yearDiscount) || 2;
      price = (price * (12 - yearDiscount)) / 12;
      price = Math.ceil(price);
      price = price * 12;
      return price;
    },
    getPricePerYearWithoutDiscount: state => id => {
      const subscriptionPlan = state.data.find(item => item.id == id);
      let price = Number(subscriptionPlan.price);
      price = price * 12;
      return price;
    },
    getPricePerMonthForYearSubscription: state => id => {
      const subscriptionPlan = state.data.find(item => item.id == id);
      let pricePerYear = Number(subscriptionPlan.price);
      const yearDiscount = Number(subscriptionPlan.yearDiscount) || 2;
      pricePerYear = pricePerYear * (12 - yearDiscount);
      const price = pricePerYear / 12;
      return Math.ceil(price);
    },
  },
  actions: {
    fetch() {
      return APISubscriptionPlans.get()
        .then(response => {
          this.data = response.data;
        })
        .catch(err => {
          throw new Error("Get SubscriptionPlan Error", err);
        });
    },
    getStandardSubscriptionPlans() {
      return APISubscriptionPlans.getStandardSubscriptionPlans()
        .then(response => {
          if (response.data.length > 0) this.data = response.data;
        })
        .catch(err => {
          throw new Error("Get SubscriptionPlan Error", err);
        });
    },
    getStandardSubscriptionPlansSignUpWizzard() {
      return APISubscriptionPlans.getStandardSubscriptionPlansSignUpWizzard()
        .then(response => {
          if (response.data.length > 0) this.data = response.data;
        })
        .catch(err => {
          throw new Error("Get SubscriptionPlan Error", err);
        });
    },
    insertOrUpdate(object) {
      if (!object) return;

      const index = this.data.findIndex(item => item.id == object.id);
      if (index != "-1") {
        // Update
        this.data[index] = object;
      } else {
        // Insert
        this.data.push(object);
      }
    },
    delete(objectId) {
      const index = this.data.findIndex(item => item.id == objectId);
      if (index != "-1") this.data.splice(index, 1);
    },
  },
});
