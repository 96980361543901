import { defineStore } from "pinia";

import { APINotificationInterval } from "@/api/notification-interval.js";
import { notificationIntervalTypes } from "@/constants/notificationInterval.js";

export const useNotificationIntervalStore = defineStore("notificationIntervals", {
  state: () => {
    return {
      totalItems: 0,
      displayedItems: 0,
      itemsPerPage: 10,

      data: [],
    };
  },
  getters: {
    get: state => id => state.data.find(type => type.id === id),
    isPagerNeeded: state => {
      return state.totalItems > state.displayedItems;
    },
    getFiltered:
      state =>
      (type, tunnel, allDay = false) => {
        return state.data.filter(
          item => item.type === type && item.tunnel === tunnel && item.allDay === allDay
        );
      },
    getAll(state) {
      return state.data.map(item => ({ ...item }));
    },
    getNotificationIntervalsTypeBefore(state) {
      return state.data
        .filter(item => item.type == notificationIntervalTypes.TYPE_NOTIFICATION_BEFORE)
        .map(item => ({ ...item }));
    },
    getNotificationIntervalsTypeAfter(state) {
      return state.data
        .filter(item => item.type == notificationIntervalTypes.TYPE_NOTIFICATION_AFTER)
        .map(item => ({ ...item }));
    },
  },
  actions: {
    apiFetchPaged(payload) {
      let page = 1;
      if (Object.hasOwn(payload, "page")) {
        page = payload.page;
      }
      return APINotificationInterval.getPaged(searchInput, this.itemsPerPage, page)
        .then(response => {
          if (page > 1) {
            this.data = this.data.concat(response.data.items);
          } else {
            this.data = response.data.items;
          }

          this.totalItems = response.data.totalItems;
          this.displayedItems = page * this.itemsPerPage;
        })
        .catch(err => {
          throw new Error("Get NotificationInterval Error", err);
        });
    },
    apiFetch() {
      return APINotificationInterval.get()
        .then(response => {
          this.data = response.data.items;
        })
        .catch(err => {
          throw new Error("Get NotificationInterval Error", err);
        });
    },
    apiCreate(payload) {
      return APINotificationInterval.post(payload).then(res => {
        this.data.push(res.data);
      });
    },
    apiUpdate(payload) {
      return APINotificationInterval.put(payload.id, payload).then(res => {
        const index = this.data.findIndex(item => item.id == res.data.id);
        this.data[index] = res.data;
      });
    },
    apiRemove(payload) {
      return APINotificationInterval.delete(payload.id).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data.splice(index, 1);
      });
    },
    insertOrUpdate(object) {
      if (!object) return;

      const index = this.data.findIndex(item => item.id == object.id);
      if (index != "-1") {
        // Update
        this.data[index] = object;
      } else {
        // Insert
        this.data.push(object);
      }
    },
    delete(objectId) {
      const index = this.data.findIndex(item => item.id == objectId);
      if (index != "-1") this.data.splice(index, 1);
    },
  },
});
