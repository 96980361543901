import moment from "moment";
import { defineStore } from "pinia";
import { useEventsStore } from "./models-events.js";

export const useSearchStore = defineStore("search", {
  state: () => {
    return {
      calendarSearchMode: false,
      calendarViewBeforeSearch: undefined,
      calendarCurrentView: undefined,
    };
  },
  actions: {
    updateCalendarCurrentView(value, { saveInLocalStorage = true, syncListFilter = true } = {}) {
      if (value != "listView") {
        this.calendarSearchMode = false;
      }

      if (
        syncListFilter &&
        value === "listView" &&
        localStorage.getItem("agenda-selected-calendar-current-date") &&
        !localStorage.getItem("agenda-selected-calendar-list-filter")
      ) {
        const eventsStore = useEventsStore();

        const now = moment().format("YYYY-MM-DD");
        const calendarCurrentDate = localStorage.getItem("agenda-selected-calendar-current-date");

        if (now === calendarCurrentDate) {
          eventsStore.setCalendarListFilter("now");
        } else if (now > calendarCurrentDate) {
          eventsStore.setCalendarListFilter("past");
        } else if (now < calendarCurrentDate) {
          eventsStore.setCalendarListFilter("future");
        }
      }

      this.calendarCurrentView = value;
      if (saveInLocalStorage) localStorage.setItem("agenda-selected-calendar-current-view", value);
    },
  },
});
