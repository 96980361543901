import { defineStore } from "pinia";

import { APIUnavailabilities } from "@/api/unavailabilities.js";
import { useAgendasStore } from "@/stores/models-agendas.js";
import moment from "moment";
import { timeFilterConditionCheck } from "@/utils/dates";

export const useUnavailabilitiesStore = defineStore("unavailabilities", {
  state: () => {
    return {
      data: [],
    };
  },
  getters: {
    get: state => id => state.data.find(type => type.id == id),
    getAll(state) {
      return state.data.map(item => ({ ...item }));
    },
    getUnavailabilitiesForList:
      state =>
      (timeFilter, agendaId, order = "desc") => {
        const agendasStore = useAgendasStore();

        return state.data
          .filter(unavailability => {
            const isSameAgenda = agendaId ? agendaId === unavailability.agendaId : true;
            return isSameAgenda && timeFilterConditionCheck(timeFilter, unavailability);
          }) // NOTE: Default order descending by dateStart
          .sort((a, b) => {
            if (order == "desc") {
              return a.dateStart < b.dateStart ? 1 : -1;
            } else {
              return a.dateStart > b.dateStart ? 1 : -1;
            }
          })
          .map(unavailability => ({
            ...unavailability,
            agenda: agendasStore.get(unavailability.agendaId),
            type: "unavailability",
          }));
      },
    getUnavailabilitiesForCalendar: state => (date, viewType, agendaId) => {
      let dateStart = null;
      let dateEnd = null;
      if (viewType == "dayGridMonth") {
        dateStart = moment(date).startOf("month").subtract(15, "days").local();
        dateEnd = moment(date).endOf("month").add(15, "days").local();
      } else if (viewType == "timeGridWeek") {
        dateStart = moment(date).startOf("week").local();
        dateEnd = moment(date).endOf("week").local();
      } else if (viewType == "timeGridDay") {
        dateStart = moment(date).startOf("day").local();
        dateEnd = moment(date).endOf("day").local();
      }

      return state.data
        .filter(unavailability => {
          // ...
          const isSameAgenda = agendaId ? agendaId === unavailability.agendaId : true;
          return (
            isSameAgenda &&
            moment(unavailability.dateStart).isBefore(dateEnd) &&
            moment(unavailability.dateEnd).isAfter(dateStart)
          );
        })

        .map(unavailability => ({
          ...unavailability,
        }));
    },
  },
  actions: {
    apiGet(id) {
      return APIUnavailabilities.get(id).then(res => {
        this.insertOrUpdate(res.data);
      });
    },
    apiCreate(payload) {
      return APIUnavailabilities.post(payload).then(res => {
        this.data.push(res.data);
      });
    },
    apiUpdate(payload) {
      return APIUnavailabilities.put(payload.id, payload).then(res => {
        const index = this.data.findIndex(item => item.id == res.data.id);
        this.data[index] = res.data;
      });
    },
    apiRemove(payload) {
      return APIUnavailabilities.delete(payload.id).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data.splice(index, 1);
      });
    },
    insertOrUpdate(object) {
      if (!object) return;

      const index = this.data.findIndex(item => item.id == object.id);
      if (index != "-1") {
        // Update
        this.data[index] = object;
      } else {
        // Insert
        this.data.push(object);
      }
    },
    delete(objectId) {
      const index = this.data.findIndex(item => item.id == objectId);
      if (index != "-1") this.data.splice(index, 1);
    },
    duplicate(payload) {
      return new Promise((resolve, reject) => {
        APIUnavailabilities.duplicate(payload.id, payload.data)
          .then(res => {
            this.data.push(res.data);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
  },
});
