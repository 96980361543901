import { defineStore } from "pinia";

import { APISubscriptions } from "@/api/subscriptions.js";
import { useSubscriptionTypesStore } from "@/stores/models-subscriptionTypes.js";
import { useEventObjectsStore } from "@/stores/models-eventObjects.js";
import moment from "moment";
import { companySettingsNames } from "@/constants/companySettings.js";

export const useSubscriptionsStore = defineStore("subscriptions", {
  state: () => {
    return {
      data: [],

      totalItems: 0,
      displayedItems: 0,
      page: 1,
      itemsPerPage: 10,
      activeSearchTerm: "",
      isSearchInputFocused: false,
      listFilter: "active",
      accessLinkCode: null,
    };
  },
  getters: {
    get: state => id => state.data.find(item => item.id == id),
    getAll(state) {
      return state.data.map(item => ({ ...item }));
    },
    getAllSorted: state => order => {
      return state.data
        .sort((a, b) => {
          if (order == "desc") {
            return a.id < b.id ? 1 : -1;
          } else {
            return a.id > b.id ? 1 : -1;
          }
        })
        .map(item => ({ ...item }));
    },
    getAllIDs(state) {
      return (
        state.data
          // NOTE: Default order ascending by name
          .sort((a, b) => {
            return a.name > b.name ? 1 : -1;
          })
          .map(item => item.id)
      );
    },
    // ???: ??
    getActiveSearchTerm: state => {
      return state.activeSearchTerm;
    },
    getActiveSubscriptionsSorted: state => order => {
      return state.data
        .filter(subscription => {
          return moment(new Date(subscription.dateEnd)).isSameOrAfter(moment());
        })
        .sort((a, b) => {
          if (order == "desc") {
            return a.id < b.id ? 1 : -1;
          } else {
            return a.id > b.id ? 1 : -1;
          }
        })
        .map(item => ({ ...item }));
    },
    getExpiredSubscriptionsSorted: state => order => {
      return state.data
        .filter(subscription => {
          return moment(new Date(subscription.dateEnd)).isBefore(moment());
        })
        .sort((a, b) => {
          if (order == "desc") {
            return a.id < b.id ? 1 : -1;
          } else {
            return a.id > b.id ? 1 : -1;
          }
        })
        .map(item => ({ ...item }));
    },
    getForList:
      state =>
      (dateEndAfter, dateEndBefore, order = "desc") => {
        const subscriptionTypesStore = useSubscriptionTypesStore();

        return (
          state.data
            .filter(subscription => {
              return (
                !subscription.eventGroupId &&
                (dateEndAfter
                  ? moment(new Date(subscription.dateEnd)).isSameOrAfter(
                      moment(new Date(dateEndBefore))
                    )
                  : true) &&
                (dateEndBefore
                  ? moment(new Date(subscription.dateEnd)).isSameOrBefore(
                      moment(new Date(dateEndBefore))
                    )
                  : true)
              );
            })
            // NOTE: Default order descending by dateStart
            .sort((a, b) => {
              if (order == "desc") {
                return a.dateStart < b.dateStart ? 1 : -1;
              } else {
                return a.dateStart > b.dateStart ? 1 : -1;
              }
            })
            .map(subscription => ({
              ...subscription,
              subscriptionType: subscriptionTypesStore.get(subscription.subscriptionTypeId),
            }))
        );
      },
    isPagerNeeded: state => {
      return state.totalItems > state.displayedItems;
    },
    getAccessLinkCode: state => {
      return state.accessLinkCode;
    },
    hasEnabledRecurrentOnlinePayment: state => id => {
      const subscription = state.data.find(item => item.id == id);
      if (subscription?.company?.companySettings) {
        const companySetting = subscription.company.companySettings.find(
          item => item.name == companySettingsNames.SETTING_ENABLED_RECURRENT_ONLINE_PAYMENTS
        );
        return companySetting && companySetting.value == "1";
      }
      return false;
    },
  },
  actions: {
    fetchPaged({ searchInput, dateEndAfter, dateEndBefore, pagination, pageNr, order }) {
      APISubscriptions.getAll(
        searchInput,
        null,
        null,
        dateEndAfter,
        dateEndBefore,
        pagination,
        pageNr,
        order
      ).then(res => {
        res.data.items.forEach(subscription => {
          this.insertOrUpdate(subscription);
        });

        this.totalItems = res.data.totalItems;
        this.displayedItems = pageNr * this.itemsPerPage;
        this.page = pageNr;
      });
    },
    apiFetch() {
      return APISubscriptions.get()
        .then(response => {
          this.data = response.data.items;
        })
        .catch(err => {
          throw new Error("Get Categories Error", err);
        });
    },
    apiGet(id) {
      return APISubscriptions.get(id).then(res => {
        this.insertOrUpdate(res.data);
      });
    },
    apiCreate(payload) {
      return new Promise((resolve, reject) => {
        APISubscriptions.post(payload.subscription)
          .then(res => {
            this.data.push(res.data);
            this.inserOrUpdateEventObjectAndSubType(res.data);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    apiUpdate(payload) {
      return APISubscriptions.put(payload.id, payload.subscription).then(res => {
        const index = this.data.findIndex(item => item.id == res.data.id);
        this.data[index] = res.data;
        this.inserOrUpdateEventObjectAndSubType(res.data);
      });
    },
    apiRemove(payload) {
      return APISubscriptions.delete(payload.id).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data.splice(index, 1);
      });
    },
    insertOrUpdate(object) {
      if (!object) return;

      const index = this.data.findIndex(item => item.id == object.id);
      if (index != "-1") {
        // Update
        this.data[index] = object;
      } else {
        // Insert
        this.data.push(object);
      }
      this.inserOrUpdateEventObjectAndSubType(object);
    },
    delete(objectId) {
      const index = this.data.findIndex(item => item.id == objectId);
      if (index != "-1") this.data.splice(index, 1);
    },
    performSearch(activeSearchTerm) {
      this.setActiveSearchTerm(activeSearchTerm);
    },
    resetSearch() {
      this.setActiveSearchTerm("");
    },
    inserOrUpdateEventObjectAndSubType(subscription) {
      if (subscription.subscriptionType) {
        const subscriptionTypesStore = useSubscriptionTypesStore();
        subscriptionTypesStore.insertOrUpdate(subscription.subscriptionType);
      }
      if (subscription.eventObject) {
        const eventObjectsStore = useEventObjectsStore();
        eventObjectsStore.insertOrUpdate(subscription.eventObject);
      }
    },
    // ??? TODO:
    setActiveSearchTerm(activeSearchTerm) {
      this.activeSearchTerm = activeSearchTerm;
    },
    changePaymentStatus(id, paymentStatus, paymentDate) {
      return APISubscriptions.changePaymentStatus(id, { paymentStatus, paymentDate }).then(res => {
        this.insertOrUpdate(res.data);
      });
    },
    updateRecurringForClientSpace(payload) {
      return APISubscriptions.updateRecurringForClientSpace(payload.id, payload.reqBody).then(
        () => {
          const index = this.data.findIndex(item => item.id == payload.id);
          this.data[index]["isRecurrent"] = payload.reqBody.isRecurrent;
        }
      );
    },
  },
});
