import { defineStore } from "pinia";

import { APIEventTypes } from "@/api/event-types.js";

export const useEventTypesStore = defineStore("eventTypes", {
  state: () => {
    return {
      data: [],
      allowCreateEventType: true,
    };
  },
  getters: {
    get: state => id => state.data.find(type => type.id === id),
    getAll(state) {
      return state.data.map(item => ({ ...item }));
    },
    getNotDeleted(state) {
      return state.data
        .filter(item => {
          // TODO: https://app.clickup.com/t/2495903/FIC-729
          return !item.deleted;
        })
        .map(item => ({ ...item }));
    },
    /* REVIEW: This should be done un API not in store
     * https://app.clickup.com/t/2495903/FIC-729
     */
    getNotDeletedSorted: state => order => {
      return state.data
        .filter(item => {
          // TODO: https://app.clickup.com/t/2495903/FIC-729
          return !item.deleted;
        })
        .sort((a, b) => {
          if (order == "desc") {
            return a.id < b.id ? 1 : -1;
          } else {
            return a.id > b.id ? 1 : -1;
          }
        })
        .map(item => ({ ...item }));
    },
  },
  actions: {
    apiCreate(payload) {
      return APIEventTypes.post(payload.eventType).then(res => {
        this.data.push(res.data);
      });
    },
    apiUpdate(payload) {
      return APIEventTypes.put(payload.id, payload.eventType).then(res => {
        const index = this.data.findIndex(item => item.id == res.data.id);
        this.data[index] = res.data;
      });
    },
    apiRemove(payload) {
      return APIEventTypes.delete(payload.id).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data.splice(index, 1);
      });
    },
    apiCheckAllowCreate() {
      return APIEventTypes.checkAllowCreate().then(res => {
        if (res.data?.allowed == false) {
          this.allowCreateEventType = false;
        }
      });
    },
    insertOrUpdate(object) {
      if (!object) return;

      const index = this.data.findIndex(item => item.id == object.id);
      if (index != "-1") {
        // Update
        this.data[index] = object;
      } else {
        // Insert
        this.data.push(object);
      }
    },
    delete(objectId) {
      const index = this.data.findIndex(item => item.id == objectId);
      if (index != "-1") this.data.splice(index, 1);
    },
  },
});
