import { defineStore } from "pinia";
import { breakpoints } from "@/constants/breakpoints";

export const useBreakpointsStore = defineStore("breakpoints", {
  state: () => {
    return {
      currentWindowWidth: 0,
      currentBreakpoint: "",
    };
  },

  getters: {
    screenLtSm(state) {
      return state.currentWindowWidth < breakpoints.sm;
    },
    screenGteSm(state) {
      return state.currentWindowWidth >= breakpoints.sm;
    },

    screenLtMd(state) {
      return state.currentWindowWidth < breakpoints.md;
    },
    screenGteMd(state) {
      return state.currentWindowWidth >= breakpoints.md;
    },

    screenLtLg(state) {
      return state.currentWindowWidth < breakpoints.lg;
    },
    screenGteLg(state) {
      return state.currentWindowWidth >= breakpoints.lg;
    },

    screenLtXl(state) {
      return state.currentWindowWidth < breakpoints.xl;
    },
    screenGteXl(state) {
      return state.currentWindowWidth >= breakpoints.xl;
    },

    screenLtXxl(state) {
      return state.currentWindowWidth < breakpoints.xxl;
    },
    screenGteXxl(state) {
      return state.currentWindowWidth >= breakpoints.xxl;
    },
  },

  actions: {
    setCurrentWindowWidth(width) {
      this.currentWindowWidth = width;
    },
    setCurrentBreakpoint(width) {
      let breakpoint = "xs";

      if (width >= breakpoints.xxl) breakpoint = "xxl";
      else if (width >= breakpoints.xl) breakpoint = "xl";
      else if (width >= breakpoints.lg) breakpoint = "lg";
      else if (width >= breakpoints.md) breakpoint = "md";
      else if (width >= breakpoints.sm) breakpoint = "sm";

      this.currentBreakpoint = breakpoint;
    },
  },
});
