import { defineStore } from "pinia";

import { jwtDecode } from "jwt-decode";

import { APIAuth } from "@/api/auth.js";
import { userRoles } from "@/constants/users.js";
import { setAuthInfo, resetAuthInfo, resetAgendasInfo } from "@/utils/local-storage";
import { useUsersStore } from "./models-users.js";
import { useCompaniesStore } from "./models-companies.js";

export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      accessToken: localStorage.getItem("access-token") || "",
      isAuthContactSpace: false,
    };
  },
  getters: {
    isAuthenticated: state => !!state.accessToken,
    isAuthenticatedContactSpace: state => state.isAuthContactSpace,

    currentUserRoles(state) {
      try {
        const decodedToken = jwtDecode(state.accessToken);
        return decodedToken.roles;
      } catch {
        return [];
      }
    },
    isCompanyAdmin() {
      try {
        return this.currentUserRoles.includes(userRoles.COMPANY_ADMIN);
      } catch {
        return false;
      }
    },
    isCompanyUser() {
      try {
        return this.currentUserRoles.includes(userRoles.COMPANY_USER);
      } catch {
        return false;
      }
    },
  },
  actions: {
    setAuthInfo(payload) {
      const { accessToken, refreshToken } = payload;

      this.accessToken = accessToken;

      setAuthInfo(accessToken, refreshToken);
    },
    removeAuthInfo() {
      this.accessToken = "";

      resetAuthInfo();

      resetAgendasInfo();

      // !!!: ..
      location.reload();
    },

    registerUserCompanyAdmin(payload) {
      return new Promise((resolve, reject) => {
        APIAuth.registerUserCompanyAdmin(payload)
          .then(res => {
            this.setAuthInfo({
              accessToken: res.data.accessToken,
              refreshToken: res.data.refreshToken,
            });
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    registerUserCompanyUser(payload) {
      return new Promise((resolve, reject) => {
        APIAuth.registerUserCompanyUser(payload)
          .then(res => {
            const usersStore = useUsersStore();
            usersStore.insertOrUpdate(res.data);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    authenticate(payload) {
      return new Promise((resolve, reject) => {
        APIAuth.requestToken(payload)
          .then(res => {
            this.setAuthInfo({
              accessToken: res.data.accessToken,
              refreshToken: res.data.refreshToken,
            });
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    deauthenticate() {
      this.removeAuthInfo();
    },

    loginGoogle(payload) {
      return new Promise((resolve, reject) => {
        APIAuth.loginGoogle(payload)
          .then(res => {
            this.setAuthInfo({
              accessToken: res.data.accessToken,
              refreshToken: res.data.refreshToken,
            });
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    loginFacebook(payload) {
      return new Promise((resolve, reject) => {
        APIAuth.loginFacebook(payload)
          .then(res => {
            this.setAuthInfo({
              accessToken: res.data.accessToken,
              refreshToken: res.data.refreshToken,
            });
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    refreshToken() {
      return new Promise((resolve, reject) => {
        APIAuth.refreshToken({
          refreshToken: localStorage.getItem("refresh-token"),
        })
          .then(res => {
            this.setAuthInfo({
              accessToken: res.data.accessToken,
            });
            resolve(res);
          })
          .catch(err => {
            this.deauthenticate();
            reject(err);
          });
      });
    },

    resetPasswordRequest(payload) {
      return APIAuth.resetPasswordRequest(payload);
    },
    resetPasswordCheckToken(payload) {
      return APIAuth.resetPasswordCheckToken(payload);
    },
    resetPasswordUpdateCredentials(payload) {
      return APIAuth.resetPasswordUpdateCredentials(payload);
    },
    changePassword(payload) {
      return APIAuth.changePassword(payload);
    },

    checkUniqueEmail(email) {
      return APIAuth.checkUniqueEmail(email);
    },

    updateUser(userData) {
      return new Promise((resolve, reject) => {
        APIAuth.updateUser(userData)
          .then(res => {
            // ???: duplicate user info...
            const companiesStore = useCompaniesStore();
            companiesStore.upsertCompanyUser(res.data);

            const usersStore = useUsersStore();
            usersStore.upsertMe(res.data);

            if (res.data?.email) localStorage.setItem("user-email", res.email);

            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    setAuthContactSpace(value) {
      this.isAuthContactSpace = value;
    },
  },
});
