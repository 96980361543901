import { onMounted, onUnmounted } from "vue";
import { useBreakpointsStore } from "@/stores/breakpoints.js";

export default function useBreakpoints() {
  const breakpointsStore = useBreakpointsStore();

  function setCurrentWindowWidth(width) {
    breakpointsStore.setCurrentWindowWidth(width);
  }
  function setCurrentBreakpoint(width) {
    breakpointsStore.setCurrentBreakpoint(width);
  }

  setCurrentWindowWidth(window.innerWidth);
  setCurrentBreakpoint(window.innerWidth);

  function onWidthChange() {
    setCurrentWindowWidth(window.innerWidth);
    setCurrentBreakpoint(window.innerWidth);
  }

  onMounted(() => window.addEventListener("resize", onWidthChange));
  onUnmounted(() => window.removeEventListener("resize", onWidthChange));
}
