<script setup>
// import ReloadPrompt from "@/vue/components/ReloadPrompt.vue";

import useBreakpoints from "@/hooks/breakpoints";
useBreakpoints();
</script>

<template>
  <router-view />
  <!-- <ReloadPrompt /> -->
</template>

<style lang="scss">
@import "bootstrap/scss/bootstrap";

$bootstrap-icons-font-src:
  url("bootstrap-icons/font/fonts/bootstrap-icons.woff2") format("woff2"),
  url("bootstrap-icons/font/fonts/bootstrap-icons.woff") format("woff");
@import "bootstrap-icons/font/bootstrap-icons";
</style>

<style lang="css">
.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}
[data-tippy-root] {
  max-width: calc(100vw - 10px);
}
.tippy-box {
  position: relative;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  white-space: normal;
  outline: 0;
  transition-property: transform, visibility, opacity;
}
.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}
.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
}
.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}
.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left;
}
.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}
.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
}
.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}
.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333;
}
.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
}
.tippy-content {
  position: relative;
  padding: 5px 9px;
  z-index: 1;
}
.tippy-box[data-theme~="translucent"] {
  background-color: rgba(0, 0, 0, 0.7);
}
.tippy-box[data-theme~="translucent"] > .tippy-arrow {
  width: 14px;
  height: 14px;
}
.tippy-box[data-theme~="translucent"][data-placement^="top"] > .tippy-arrow:before {
  border-width: 7px 7px 0;
  border-top-color: rgba(0, 0, 0, 0.7);
}
.tippy-box[data-theme~="translucent"][data-placement^="bottom"] > .tippy-arrow:before {
  border-width: 0 7px 7px;
  border-bottom-color: rgba(0, 0, 0, 0.7);
}
.tippy-box[data-theme~="translucent"][data-placement^="left"] > .tippy-arrow:before {
  border-width: 7px 0 7px 7px;
  border-left-color: rgba(0, 0, 0, 0.7);
}
.tippy-box[data-theme~="translucent"][data-placement^="right"] > .tippy-arrow:before {
  border-width: 7px 7px 7px 0;
  border-right-color: rgba(0, 0, 0, 0.7);
}
.tippy-box[data-theme~="translucent"] > .tippy-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}
.tippy-box[data-theme~="translucent"] > .tippy-svg-arrow {
  fill: rgba(0, 0, 0, 0.7);
}
</style>
