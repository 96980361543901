import axios from "axios";
import qs from "qs";

import { Links } from "@/api/links.js";

export class Agendas {
  put(id, item) {
    return axios.put(Links.AGENDA_LINK(id), item);
  }
  post(item) {
    return axios.post(Links.AGENDAS_LINK(), item);
  }
  delete(id) {
    return axios.delete(Links.AGENDA_LINK(id));
  }
  getInfoForPublicAgenda(agendaSlug) {
    return axios.get(Links.GET_INFO_FOR_PUBLIC_AGENDA(agendaSlug));
  }
  getAvailableDatesForMonth(agendaSlug, date, isAllDay, { eventTypeSlug, eventTypesSlugs }) {
    const params = {
      agendaSlug,
      date,
      eventTypeSlug,
      eventTypesSlugs,
    };
    return axios.get(
      isAllDay
        ? Links.GET_AVAILABLE_ALL_DAY_SPOTS_FOR_MONTH()
        : Links.GET_AVAILABLE_DATES_FOR_MONTH(),
      { params }
    );
  }
  getAvailableSpotsForDay(agendaSlug, date, { eventTypeSlug, eventTypesSlugs }) {
    const params = {
      agendaSlug,
      eventTypeSlug,
      eventTypesSlugs,
      date,
    };
    return axios.get(Links.GET_AVAILABLE_SPOTS_FOR_DAY(), { params });
  }
  createEventOnPublicAgenda(agendaSlug, info) {
    return axios.post(Links.CREATE_EVENT_ON_PUBLIC_AGENDA(agendaSlug), info);
  }
  getPlanner(agendaSlug) {
    return axios.get(Links.GET_PLANNER(agendaSlug));
  }
  getPlannerEvents(
    authToken,
    dateStart = null,
    dateEnd = null,
    pagination = true,
    page = 1,
    itemsPerPage = 10,
    order = "desc"
  ) {
    const params = {};
    if (dateStart) {
      params["dateStart"] = {};
      params["dateStart"]["after"] = dateStart;
    }
    if (dateEnd) {
      params["dateEnd"] = {};
      params["dateEnd"]["before"] = dateEnd;
    }
    if (!pagination) {
      params["pagination"] = false;
    } else {
      params["page"] = page;
      params["itemsPerPage"] = itemsPerPage;
    }

    params["order[dateStart]"] = order;
    params["authToken"] = authToken;

    return axios.get(Links.GET_PLANNER_EVENTS() + "?" + qs.stringify(params));
  }
  getPlannerAuth(authDetails) {
    const params = {};
    params["hash"] = authDetails.hash;
    params["password"] = authDetails.password;
    params["phone"] = authDetails.phone;
    return axios.get(Links.GET_PLANNER_AUTH() + "?" + qs.stringify(params));
  }
  getAdditionalNewAgendaPaymentOrderStatus(orderId, agendaName) {
    const params = {};
    params["orderId"] = orderId;
    params["agendaName"] = agendaName;
    return axios.get(
      Links.GET_ADDITIONAL_NEW_AGENDA_PAYMENT_ORDER_STATUS() + "?" + qs.stringify(params)
    );
  }
  getMatchedPossibleAppointments(agendaId, eventTypeId, date = null) {
    const params = {};
    if (agendaId) {
      params["agendaId"] = agendaId;
    }
    if (eventTypeId) {
      params["eventTypeId"] = eventTypeId;
    }
    if (date) {
      params["date"] = date;
    }
    return axios.get(
      Links.AGENDAS_LINK() + "/matched-possible-appointments" + "?" + qs.stringify(params)
    );
  }
}

export const APIAgendas = new Agendas();
