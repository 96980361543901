import { defineStore } from "pinia";

import { APISubscriptionTypes } from "@/api/subscription-types.js";
import { useCategoriesStore } from "@/stores/models-category.js";

export const useSubscriptionTypesStore = defineStore("subscriptionTypes", {
  state: () => {
    return {
      data: [],
      totalItems: 0,
      displayedItems: 0,
      itemsPerPage: 10,
      allowCreateSubscriptionType: true,
    };
  },
  getters: {
    get: state => id => state.data.find(type => type.id == id),
    getAll(state) {
      return state.data.map(item => ({ ...item }));
    },
    getAllSorted: state => order => {
      return state.data
        .sort((a, b) => {
          if (order == "desc") {
            return a.createdAt < b.createdAt ? 1 : -1;
          } else {
            return a.createdAt > b.createdAt ? 1 : -1;
          }
        })
        .map(item => ({ ...item }));
    },
    isPagerNeeded: state => {
      return state.totalItems > state.displayedItems;
    },
  },
  actions: {
    apiFetchPaged(payload) {
      let page = 1;
      if (Object.hasOwn(payload, "page")) {
        page = payload.page;
      }
      return APISubscriptionTypes.getPaged(this.itemsPerPage, page)
        .then(response => {
          if (page > 1) {
            this.data = this.data.concat(response.data.items);
          } else {
            this.data = response.data.items;
          }

          this.totalItems = response.data.totalItems;
          this.displayedItems = page * this.itemsPerPage;
        })
        .catch(err => {
          throw new Error("Get SubscriptionType Paged Error", err);
        });
    },
    apiFetch() {
      return APISubscriptionTypes.fetch()
        .then(response => {
          this.data = response.data.items;
          this.totalItems = response.data.totalItems;
        })
        .catch(err => {
          throw new Error("Get SubscriptionType Error", err);
        });
    },
    apiGet(id) {
      return APISubscriptionTypes.get(id).then(res => {
        this.insertOrUpdate(res.data);
      });
    },
    apiCreate(payload) {
      return APISubscriptionTypes.post(payload.subscriptionType).then(res => {
        this.data.push(res.data);
      });
    },
    apiUpdate(payload) {
      return APISubscriptionTypes.put(payload.id, payload.subscriptionType).then(res => {
        const index = this.data.findIndex(item => item.id == res.data.id);
        this.data[index] = res.data;
      });
    },
    apiRemove(payload) {
      return APISubscriptionTypes.delete(payload.id).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data.splice(index, 1);
      });
    },
    apiCheckAllowCreate() {
      return APISubscriptionTypes.checkAllowCreate().then(res => {
        if (res.data?.allowed == false) {
          this.allowCreateSubscriptionType = false;
        }
      });
    },
    insertOrUpdate(object) {
      if (!object) return;

      const index = this.data.findIndex(item => item.id == object.id);
      if (typeof this.data[index] === "undefined") {
        // Insert
        this.data.push(object);
      } else {
        // Update
        this.data[index] = object;
      }
    },
    insert(objects) {
      if (!objects) return;
      // NOTE: Add subscriptionTypes
      this.data = objects;

      // NOTE: Add categories
      const categoriesStore = useCategoriesStore();
      for (const object of objects) {
        for (const category of object.categories) {
          categoriesStore.insertOrUpdate(category);
        }
      }
    },
    delete(objectId) {
      const index = this.data.findIndex(item => item.id == objectId);
      if (index != "-1") this.data.splice(index, 1);
    },
  },
});
