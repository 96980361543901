const userRoles = Object.freeze({
  SUPER_ADMIN: "ROLE_SUPER_ADMIN",
  ADMIN: "ROLE_ADMIN",
  USER: "ROLE_USER",
  API: "ROLE_API",

  COMPANY_ADMIN: "ROLE_COMPANY_ADMIN",
  COMPANY_USER: "ROLE_COMPANY_USER",
});

const errorTypes = Object.freeze({
  EMAIL_ALREADY_EXISTS: "email_already_exists",
  GOOGLE_FACEBOOK_CREDENTIALS_NOT_FOUND: "google_facebook_credentials_not_found",
  GOOGLE_FACEBOOK_NO_ACCOUNT_AVAILABLE: "google_facebook_no_account_available",
});

const permissionNames = Object.freeze({
  COMPANY: "company",

  AGENDAS: "agendas",
  EVENTS: "events",
  EVENTS_REPORTS: "eventsReports",
  SUBSCRIPTIONS: "subscriptions",
  SUBSCRIPTIONS_REPORTS: "subscriptionsReports",
  EVENT_TYPES: "eventTypes",
  SUBSCRIPTION_TYPES: "subscriptionTypes",
  EVENT_OBJECTS: "eventObjects",
  ANNOUNCES: "announces",
});
const permissionRights = Object.freeze({
  CREATE: "create",
  READ: "read",
  WRITE: "write",
  DELETE: "delete",
});

export { userRoles, errorTypes, permissionRights, permissionNames };
