import { defineStore } from "pinia";

export const useSubdomainStore = defineStore("subomain", {
  state: () => {
    return {
      subdomain: undefined,
      companyId: undefined,
    };
  },
  actions: {
    setup(subdomain) {
      this.subdomain = subdomain.name;
      this.companyId = subdomain.companyId;
    },
  },
});
