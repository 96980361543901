import { defineStore } from "pinia";

import moment from "moment";

import { useEventTypesStore } from "@/stores/models-eventTypes.js";
import { useAgendasStore } from "@/stores/models-agendas.js";
import { timeFilterConditionCheck } from "@/utils/dates";

export const useEventGroupsStore = defineStore("eventGroups", {
  state: () => {
    return {
      data: [],
    };
  },
  getters: {
    getAll(state) {
      return state.data.map(item => ({ ...item }));
    },
    get: state => {
      return Object.values(state.data)
        .sort((a, b) => {
          if (state.order == "desc") {
            return a.id < b.id ? 1 : -1;
          } else {
            return a.id > b.id ? 1 : -1;
          }
        })
        .map(item => ({ ...item }));
    },
    getEventGroupsForCalendar: state => (date, viewType, agendaId) => {
      let dateStart = null;
      let dateEnd = null;
      if (viewType == "dayGridMonth") {
        dateStart = moment(date).startOf("month").subtract(15, "days").local();
        dateEnd = moment(date).endOf("month").add(15, "days").local();
      } else if (viewType == "timeGridWeek") {
        dateStart = moment(date).startOf("week").local();
        dateEnd = moment(date).endOf("week").local();
      } else if (viewType == "timeGridDay") {
        dateStart = moment(date).startOf("day").local();
        dateEnd = moment(date).endOf("day").local();
      }

      const eventTypesStore = useEventTypesStore();

      return state.data
        .filter(eventGroup => {
          // ...
          const isSameAgenda = agendaId ? agendaId === eventGroup.agendaId : true;
          return (
            isSameAgenda &&
            moment(eventGroup.dateStart).isBefore(dateEnd) &&
            moment(eventGroup.dateEnd).isAfter(dateStart)
          );
        })

        .map(eventGroup => ({
          ...eventGroup,
          eventType: eventTypesStore.get(eventGroup.eventTypeId),
        }));
    },
    getEventGroupsForList:
      state =>
      (timeFilter, agendaId, order = "desc") => {
        const eventTypesStore = useEventTypesStore();
        const agendasStore = useAgendasStore();

        return state.data
          .filter(eventGroup => {
            const isSameAgenda = agendaId ? agendaId === eventGroup.agendaId : true;
            return isSameAgenda && timeFilterConditionCheck(timeFilter, eventGroup);
          }) // NOTE: Default order descending by dateStart
          .sort((a, b) => {
            if (order == "desc") {
              return a.dateStart < b.dateStart ? 1 : -1;
            } else {
              return a.dateStart > b.dateStart ? 1 : -1;
            }
          })
          .map(eventGroup => ({
            ...eventGroup,
            eventType: eventTypesStore.get(eventGroup.eventTypeId),
            agenda: agendasStore.get(eventGroup.agendaId),
          }));
      },
    getByAgendaId: state => agendaId => {
      const eventTypesStore = useEventTypesStore();

      return (
        state.data
          .filter(eventGroup => {
            return eventGroup.agendaId == agendaId;
          })
          // NOTE: Default order ascending by dateStart
          .sort((a, b) => {
            return a.dateStart > b.dateStart ? 1 : -1;
          })
          .map(eventGroup => ({
            ...eventGroup,
            eventType: eventTypesStore.get(eventGroup.eventTypeId),
          }))
      );
    },
  },
  actions: {
    insertOrUpdate(object) {
      if (!object) return;

      const index = this.data.findIndex(item => item.id == object.id);
      if (index != "-1") {
        this.data[index] = object;
      } else {
        this.data.push(object);
      }
    },
    delete(objectId) {
      const index = this.data.findIndex(item => item.id == objectId);
      if (index != "-1") this.data.splice(index, 1);
    },
  },
});
