import axios from "axios";
import qs from "qs";

import { Links } from "@/api/links.js";

export class Categories {
  get() {
    return axios.get(Links.CATEGORIES_LINK());
  }
  getPaged(searchInput = null, itemsPerPage = 30, page = 1, order = "desc") {
    const params = {};
    params["page"] = page;
    params["itemsPerPage"] = itemsPerPage;
    params["order"] = order;
    if (searchInput) {
      params["searchInput"] = searchInput;
    }
    return axios.get(Links.CATEGORIES_LINK() + "?" + qs.stringify(params));
  }
  post(item) {
    return axios.post(Links.CATEGORIES_LINK(), item);
  }
  put(id, item) {
    return axios.put(Links.CATEGORY_LINK(id), item);
  }
  delete(id) {
    return axios.delete(Links.CATEGORY_LINK(id));
  }
}

export const APICategories = new Categories();
