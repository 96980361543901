import { defineStore } from "pinia";

import { APICategories } from "@/api/categories.js";

export const useCategoriesStore = defineStore("categories", {
  state: () => {
    return {
      totalItems: 0,
      displayedItems: 0,
      itemsPerPage: 3,

      data: [],
    };
  },
  getters: {
    get: state => id => state.data.find(type => type.id === id),
    getAll(state) {
      return state.data.map(item => ({ id: item.id, name: item.name }));
    },
    getAllIDs(state) {
      return (
        state.data
          // NOTE: Default order ascending by name
          .sort((a, b) => {
            return a.name > b.name ? 1 : -1;
          })
          .map(item => item)
      );
    },
    getAllSorted: state => order => {
      return state.data
        .sort((a, b) => {
          if (order == "desc") {
            return a.id < b.id ? 1 : -1;
          } else {
            return a.id > b.id ? 1 : -1;
          }
        })
        .map(item => ({ ...item }));
    },
    isPagerNeeded: state => {
      return state.totalItems > state.displayedItems;
    },
  },
  actions: {
    apiFetchPaged(payload) {
      let page = 1;
      if (Object.hasOwn(payload, "page")) {
        page = payload.page;
      }
      let searchInput = null;
      if (Object.hasOwn(payload, "searchInput")) {
        searchInput = payload.searchInput;
      }
      return APICategories.getPaged(searchInput, this.itemsPerPage, page)
        .then(response => {
          if (page > 1) {
            this.data = this.data.concat(response.data.items);
          } else {
            this.data = response.data.items;
          }

          this.totalItems = response.data.totalItems;
          this.displayedItems = page * this.itemsPerPage;
        })
        .catch(err => {
          throw new Error("Get Categories Error", err);
        });
    },
    apiFetch() {
      return APICategories.get()
        .then(response => {
          this.data = response.data.items;
        })
        .catch(err => {
          throw new Error("Get Categories Error", err);
        });
    },
    apiCreate(payload) {
      return APICategories.post(payload.category).then(res => {
        this.data.push(res.data);
      });
    },
    apiUpdate(payload) {
      return APICategories.put(payload.id, payload.category).then(res => {
        const index = this.data.findIndex(item => item.id == res.data.id);
        this.data[index] = res.data;
      });
    },
    apiRemove(payload) {
      return APICategories.delete(payload.id).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data.splice(index, 1);
      });
    },
    insertOrUpdate(object) {
      if (!object) return;

      const index = this.data.findIndex(item => item.id == object.id);
      if (index != "-1") {
        // Update
        this.data[index] = object;
      } else {
        // Insert
        this.data.push(object);
      }
    },
    delete(objectId) {
      const index = this.data.findIndex(item => item.id == objectId);
      if (index != "-1") this.data.splice(index, 1);
    },
  },
});
