import axios from "axios";

import { Links } from "@/api/links.js";

export class Auth {
  registerUserCompanyAdmin(data) {
    return axios.post(Links.REGISTER_USER_COMPANY_ADMIN(), data);
  }
  registerUserCompanyUser(data) {
    return axios.post(Links.REGISTER_USER_COMPANY_USER, data);
  }
  requestToken(data) {
    return axios.post(Links.REQUEST_TOKEN(), data);
  }
  refreshToken(data) {
    return axios.post(Links.REFRESH_TOKEN(), data);
  }
  loginGoogle(data) {
    return axios.post(Links.LOGIN_GOOGLE, data);
  }
  loginFacebook(data) {
    return axios.post(Links.LOGIN_FACEBOOK, data);
  }
  resetPasswordRequest(data) {
    return axios.post(Links.RESET_PASS_REQUEST(), data);
  }
  resetPasswordCheckToken(data) {
    return axios.post(Links.RESET_PASS_CHECK_TOKEN(), data);
  }
  resetPasswordUpdateCredentials(data) {
    return axios.post(Links.RESET_PASS_UPDATE_CREDENTIALS(), data);
  }
  changePassword(data) {
    return axios.put(Links.CHANGE_PASSWORD(), data);
  }
  checkUniqueEmail(email) {
    return axios.get(Links.CHECK_UNIQUE_EMAIL(email));
  }
  updateUser(data) {
    return axios.put(Links.UPDATE_USER(), data);
  }
  getUserName(email) {
    return axios.get(Links.USER_NAME(email));
  }
}

export const APIAuth = new Auth();
