const breakpoints = Object.freeze({
  /**
   * ???: maybe better add one more breakpoint "xxs" and modify "xs"
   * xxs: 0,
   * xs: 390,
   */

  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
});

export { breakpoints };
