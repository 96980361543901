import { createApp } from "vue";
import { createPinia } from "pinia";

import moment from "moment";
import momentTimezone from "moment-timezone";
import "moment/dist/locale/ro";
moment.locale("ro");
momentTimezone.tz.setDefault();

import * as Sentry from "@sentry/vue";

import "@/company/configs/axios.config.js";

import { router } from "@/company/router/index.js";

const pinia = createPinia();

import { resetStore } from "@/stores/plugins/reset-store";
pinia.use(resetStore);

import { plugin as VueTippy } from "vue-tippy";

import { default as BaseModal } from "@/vue/components/UI/BaseModal.vue";
import { default as SmallSpinner } from "@/vue/components/UI/SmallSpinner.vue";
import BigSpinner from "@/vue/components/UI/BigSpinner.vue";

import App from "./App.vue";
const app = createApp(App);

app.component("AppBaseModal", BaseModal).component("AppSpinner", SmallSpinner);

if (import.meta.env.PROD)
  Sentry.init({
    app,
    dsn: "https://0105f4a3b3554622a8059ae620fa6938@o4504557983170560.ingest.sentry.io/4504597903835136",
    trackComponents: true,
    hooks: ["mount", "update", "destroy"],
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        blockAllMedia: true,
      }),
    ],
    tracesSampleRate: 0,
    tracePropagationTargets: ["*.planific.ro", "*.planific.ekkode.dev", /^\//],
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 0.9,
    release: import.meta.env.VERSION,
  });

app
  .use(router)
  .use(pinia)
  .use(
    VueTippy,

    {
      directive: "tippy", // => v-tippy
      component: "tippy", // => <tippy/>
      componentSingleton: "tippy-singleton", // => <tippy-singleton/>,
      defaultProps: {
        placement: "auto-end",
        allowHTML: true,
        theme: "translucent",
      },
    }
  )
  .component("AppBigSpinner", BigSpinner)
  .mount("#app");

import { registerSW } from "virtual:pwa-register";
registerSW({ immediate: true });
