import axios from "axios";
import qs from "qs";

import { Links } from "@/api/links.js";

export class Subscriptions {
  getAll(
    searchInput = null,
    dateStartAfter = null,
    dateStartBefore = null,
    dateEndAfter = null,
    dateEndBefore = null,
    pagination = true,
    page = 1,
    order = "desc",
    itemsPerPage = 10
  ) {
    const params = {};

    // ???: ??
    if (dateStartAfter) {
      if (!params["dateStart"]) params["dateStart"] = {};
      params["dateStart"]["after"] = dateStartAfter;
    }
    // ???: ??
    if (dateStartBefore) {
      if (!params["dateStart"]) params["dateStart"] = {};
      params["dateStart"]["before"] = dateStartBefore;
    }

    if (dateEndAfter) {
      if (!params["dateEnd"]) params["dateEnd"] = {};
      params["dateEnd"]["after"] = dateEndAfter;
    }
    if (dateEndBefore) {
      if (!params["dateEnd"]) params["dateEnd"] = {};
      params["dateEnd"]["before"] = dateEndBefore;
    }

    if (!pagination) {
      params["pagination"] = false;
    } else {
      params["page"] = page;
      params["itemsPerPage"] = itemsPerPage;
    }

    if (searchInput) {
      params["searchInput"] = searchInput;
    }

    params["order[dateStart]"] = order;

    return axios.get(Links.SUBSCRIPTIONS_LINK() + "?" + qs.stringify(params));
  }
  get(id) {
    return axios.get(Links.SUBSCRIPTION_LINK(id));
  }
  post(item) {
    return axios.post(Links.SUBSCRIPTIONS_LINK(), item);
  }
  put(id, item) {
    return axios.put(Links.SUBSCRIPTION_LINK(id), item);
  }
  delete(id) {
    return axios.delete(Links.SUBSCRIPTION_LINK(id));
  }
  createPublicSubscription(item) {
    return axios.post(Links.CREATE_PUBLIC_SUBSCRIPTION_LINK(), item);
  }
  changePaymentStatus(id, data) {
    return axios.post(Links.SUBSCRIPTION_CHANGE_PAYMENT_STATUS_LINK(id), data);
  }
  updateRecurringForClientSpace(id, item) {
    return axios.put(Links.SUBSCRIPTION_UPDATE_RECURRING_FOR_CLIENT_SPACE_LINK(id), item);
  }
  createInvoice(id) {
    return axios.get(Links.SUBSCRIPTION_LINK(id) + "/create-invoice");
  }
  downloadInvoice(id) {
    return axios.get(Links.SUBSCRIPTION_LINK(id) + "/download-invoice");
  }
}

export const APISubscriptions = new Subscriptions();
