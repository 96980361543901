import { defineStore } from "pinia";

import { APIUsers } from "@/api/users";
import { userRoles } from "@/constants/users.js";
import { hasAllRights } from "@/utils/permissions";
import { useCompaniesStore } from "@/stores/models-companies.js";

export const useUsersStore = defineStore("users", {
  state: () => {
    return {
      me: null,

      data: [],
    };
  },
  getters: {
    getCompanyUsersForList(state) {
      return state.data
        .map(item => ({ ...item }))
        .filter(item => item.roles?.includes(userRoles.COMPANY_USER));
    },
    get: state => id => state.data.find(event => event.id == id),

    // permission rights...
    hasEventObjectsRights(state) {
      return hasAllRights(state.me?.permissions?.eventObjects?.rights);
    },
    hasEventTypesRights(state) {
      return hasAllRights(state.me?.permissions?.eventTypes?.rights);
    },
    hasSubscriptionTypesRights(state) {
      return hasAllRights(state.me?.permissions?.subscriptionTypes?.rights);
    },
    hasAnnouncesRights(state) {
      return hasAllRights(state.me?.permissions?.announces?.rights);
    },
    hasSubscriptionsRights(state) {
      return hasAllRights(state.me?.permissions?.subscriptions?.rights);
    },
    hasSubscriptionsReportsRights(state) {
      return hasAllRights(state.me?.permissions?.subscriptionsReports?.rights);
    },
    hasAgendasRights(state) {
      if (!Array.isArray(state.me?.agendas)) return false;

      return !!state.me.agendas.find(agenda => agenda?.UserAgenda?.permissions.rights?.length > 0);
    },
    hasEventsRights(state) {
      if (!Array.isArray(state.me?.agendas)) return false;

      return !!state.me.agendas.find(
        agenda => agenda?.UserAgenda?.permissions?.events?.rights?.length > 0
      );
    },
    hasEventsReportsRights(state) {
      if (!Array.isArray(state.me?.agendas)) return false;

      return !!state.me.agendas.find(
        agenda => agenda?.UserAgenda?.permissions?.eventsReports?.rights?.length > 0
      );
    },
    getAgendasWithEventsReportsRights(state) {
      if (!Array.isArray(state.me?.agendas)) return [];

      return state.me.agendas
        .filter(agenda => agenda?.UserAgenda?.permissions?.eventsReports?.rights?.length > 0)
        .map(item => ({ id: item.id }));
    },
    isAdmin(state) {
      return !!state.me.roles.find(role => role === userRoles.COMPANY_ADMIN);
    },
  },
  actions: {
    getCompanyUsers() {
      return new Promise((resolve, reject) => {
        APIUsers.getCompanyUsers()
          .then(res => {
            res.data.forEach(item => {
              this.insertOrUpdate(item);
            });
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    updateCompanyUser(payload) {
      return new Promise((resolve, reject) => {
        APIUsers.updateCompanyUser(payload.id, payload)
          .then(res => {
            this.insertOrUpdate(res.data);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    deleteCompanyUser(id) {
      return new Promise((resolve, reject) => {
        APIUsers.deleteCompanyUser(id)
          .then(res => {
            this.delete(id);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    updateCompanyOwnerUser(payload) {
      return new Promise((resolve, reject) => {
        APIUsers.updateCompanyOwnerUser(payload.id, payload)
          .then(res => {
            // ???: duplicate user info...
            this.upsertMe(res.data);
            const companiesStore = useCompaniesStore();
            companiesStore.upsertCompanyUser(res.data);

            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    // ???: duplicate user info, here and "company user"
    upsertMe(object) {
      if (!object) return;

      if (this.me) {
        this.me = { ...this.me, ...object };
      } else {
        this.me = object;
      }
    },
    insertOrUpdate(object) {
      if (!object) return;

      const index = this.data.findIndex(item => item.id == object.id);
      if (index != "-1") {
        this.data[index] = { ...this.data[index], ...object };
      } else {
        this.data.push(object);
      }
    },
    delete(objectId) {
      const index = this.data.findIndex(item => item.id == objectId);
      if (index != "-1") {
        this.data.splice(index, 1);
      }
    },
  },
});
