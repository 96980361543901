import axios from "axios";
import { Links } from "@/api/links.js";

export class Unavailabilities {
  get(id) {
    return axios.get(Links.UNAVAILABILITY_LINK(id));
  }
  post(item) {
    return axios.post(Links.UNAVAILABILITIES_LINK(), item);
  }
  put(id, item) {
    return axios.put(Links.UNAVAILABILITY_LINK(id), item);
  }
  delete(id) {
    return axios.delete(Links.UNAVAILABILITY_LINK(id));
  }
  duplicate(id, data) {
    return axios.post(Links.UNAVAILABILITY_DUPLICATE_LINK(id), data);
  }
}

export const APIUnavailabilities = new Unavailabilities();
