import { createRouter, createWebHistory } from "vue-router";

import { routes } from "./routes.js";

import { APICompanies } from "@/api/companies.js";
import { useSubdomainStore } from "@/stores/subdomain.js";

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async to => {
  if (to.name == "route.notFound") return true;

  try {
    const subdomainStore = useSubdomainStore();
    if (!subdomainStore.subdomain) {
      const subdomain = window.location.host.split(".")[0];

      const response = await APICompanies.checkSubdomain(subdomain);

      if (response.data && response.data.companyId) {
        subdomainStore.setup({ name: subdomain, companyId: response.data.companyId });
        return true;
      }
    } else {
      return true;
    }
  } catch (err) {
    throw new Error("Something wrong at when checking the subdomain!");
  }

  return { name: "route.notFound", replace: false };
});
