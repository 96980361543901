const planTypes = Object.freeze({
  ANNUALLY: "annually",
  MONTHLY: "monthly",
});

const planStatuses = Object.freeze({
  TRIAL: "trial",
  ACTIVE: "active",
  INACTIVE: "inactive",
  CANCELED: "canceled",
  PENDING_PAYMENT: "pending_payment",
});

const futureSubscriptionTypes = Object.freeze({
  DOWNGRADE: "downgrade",
  CANCEL: "cancel",
  UPGRADE: "upgrade",
});

const companyTypes = Object.freeze({
  SERVICE_SUBSCRIPTION: "service-subscription",
  SERVICE: "service",
  SUBSCRIPTION: "subscription",
});

const stripePaymentsEntities = Object.freeze({
  SERVICE: "service",
  SUBSCRIPTION: "subscription",
});

const stripePaymentsStatuses = Object.freeze({
  MISSING: "missing",
  NOT_CHECKED: "not_checked",
  INVALID: "invalid",
  VALID: "valid",
});

const stripePaymentsUsages = Object.freeze({
  NOT_USED: "not_used",
  USED: "used",
});

const invoiceClientTypes = Object.freeze({
  OBLIO: "oblio",
  SMARTBILL: "smartbill",
});

export {
  planTypes,
  planStatuses,
  futureSubscriptionTypes,
  companyTypes,
  stripePaymentsEntities,
  stripePaymentsStatuses,
  stripePaymentsUsages,
  invoiceClientTypes,
};
