const notificationIntervalTypes = Object.freeze({
  TYPE_NOTIFICATION_BEFORE: "notification_before",
  TYPE_NOTIFICATION_AFTER: "notification_after",
});

const notificationIntervalTunnels = Object.freeze({
  TUNNEL_SMS: "sms",
  TUNNEL_EMAIL: "email",
  TUNNEL_WHATSAPP: "whatsapp",
});

export { notificationIntervalTypes, notificationIntervalTunnels };
