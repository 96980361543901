import axios from "axios";

import { Links } from "@/api/links.js";

export class SubscriptionPlans {
  get() {
    return axios.get(Links.SUBSCRIPTION_PLANS());
  }

  getStandardSubscriptionPlans() {
    return axios.get(Links.STANDARD_SUBSCRIPTION_PLANS());
  }

  getStandardSubscriptionPlansSignUpWizzard() {
    return axios.get(Links.STANDARD_SUBSCRIPTION_PLANS_FOR_SIGNUP_WIZZARD());
  }
}

export const APISubscriptionPlans = new SubscriptionPlans();
