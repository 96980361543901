import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default function useAlert() {
  function alertToastSuccess(opts = {}) {
    const { title = "Succes", timer = 3000 } = opts;

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      showCloseButton: true,
      timer: timer,
      timerProgressBar: true,
    });

    return Toast.fire({
      icon: "success",
      title: title,
    });
  }

  function alertToastError(opts = {}) {
    const { title = "Eroare", timer = 3000 } = opts;

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      showCloseButton: true,
      timer: timer,
      timerProgressBar: true,
    });

    return Toast.fire({
      icon: "error",
      title: title,
    });
  }

  function alertToastWarning(opts = {}) {
    const { title = "Atenție", timer = 3000 } = opts;

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      showCloseButton: true,
      timer: timer,
      timerProgressBar: true,
    });

    return Toast.fire({
      icon: "warning",
      title: title,
    });
  }

  function alertConfirm(opts = {}) {
    const { title = "Confirmă", text = "Ești sigur?" } = opts;

    const commonButtonsStyle = "btn rounded-pill btn-lg mx-3 px-5 mb-3";
    return Swal.fire({
      title: title,
      text: text,
      icon: "question",
      confirmButtonText: "Da",
      denyButtonText: "Nu",
      showDenyButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: `${commonButtonsStyle} btn-primary`,
        denyButton: `${commonButtonsStyle} btn-light`,
      },
    });
  }

  function alertError(opts = {}) {
    const {
      title = "Eroare",
      text = "Ceva nu a mers bine",
      showConfirmButton = true,
      confirmButtonText = "OK",
      timer = undefined,
    } = opts;

    return Swal.fire({
      title,
      text,
      icon: "error",
      showConfirmButton,
      confirmButtonText,
      timer,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn rounded-pill btn-lg px-5 btn-danger",
      },
    });
  }

  function alertWarning(opts = {}) {
    const {
      title = "Avertisment",
      text = "",
      html = "",
      showConfirmButton = true,
      confirmButtonText = "OK",
      timer = undefined,
    } = opts;

    return Swal.fire({
      title,
      text,
      html,
      icon: "warning",
      showConfirmButton,
      confirmButtonText,
      timer,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn rounded-pill btn-lg px-5 btn-warning",
      },
    });
  }

  return {
    alertToastSuccess,
    alertToastError,
    alertToastWarning,
    alertConfirm,
    alertError,
    alertWarning,
  };
}
