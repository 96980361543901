import axios from "axios";
import qs from "qs";

import { Links } from "@/api/links.js";

export class Objects {
  getAll(searchInput = null, pagination = true, order = "desc") {
    const params = {};
    if (searchInput) {
      params["searchInput"] = searchInput;
    }
    if (!pagination) {
      params["pagination"] = false;
    }
    params["order"] = order;
    return axios.get(Links.OBJECTS_LINK() + "?" + qs.stringify(params));
  }
  getPaged(itemsPerPage = 10, searchInput = null, page = 1, order = "desc") {
    const params = {};
    if (searchInput) {
      params["searchInput"] = searchInput;
    }
    params["page"] = page;
    params["itemsPerPage"] = itemsPerPage;
    params["order"] = order;
    return axios.get(Links.OBJECTS_LINK() + "?" + qs.stringify(params));
  }
  getForAutocomplete(searchInput = null) {
    const params = {
      searchInput: searchInput,
    };
    return axios.get(Links.SEARCH_OBJECTS_AUTOCOMPLETE_LINK() + "?" + qs.stringify(params));
  }
  getAllLetters(searchInput = null) {
    const params = {
      searchLetters: searchInput,
    };
    return axios.get(Links.SEARCH_OBJECTS_LETTERS_LINK() + "?" + qs.stringify(params));
  }
  get(id) {
    return axios.get(Links.OBJECT_LINK(id));
  }
  post(item) {
    return axios.post(Links.OBJECTS_LINK(), item);
  }
  put(id, item) {
    return axios.put(Links.OBJECT_LINK(id), item);
  }
  delete(id) {
    return axios.delete(Links.OBJECT_LINK(id));
  }
  requestAccessPersonalSpace(item) {
    return axios.post(Links.CONTACT_REQUEST_ACCESS_PERSONAL_SPACE_LINK(), item);
  }
  accessPersonalSpace(item) {
    return axios.post(Links.CONTACT_ACCESS_PERSONAL_SPACE_LINK(), item);
  }
}

export const APIObjects = new Objects();
