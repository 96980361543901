<template>
  <div
    ref="modalRef"
    class="modal fade"
    tabindex="-1"
    :data-bs-backdrop="preventOutsideClickClose ? 'static' : undefined"
    :data-bs-keyboard="!preventCloseKeyboard"
  >
    <div
      class="modal-dialog"
      :class="{
        'modal-dialog-scrollable': !!scrollable,
        'modal-dialog-centered': !!centered,
        'mb-11': !!initialTour?.isRunning,
      }"
    >
      <div class="modal-content">
        <!-- HEADER -->
        <div v-if="!noHeader" class="modal-header py-1">
          <slot name="header">
            <h5 class="modal-title">
              <slot name="title" class="modal-title">Modal title</slot>
            </h5>

            <button class="btn modal-close" @click="hideModal">
              <span class="bi bi-x-lg"></span>
            </button>
          </slot>
        </div>

        <!-- BODY -->
        <div class="modal-body" :class="bodyClass">
          <slot name="body"></slot>
        </div>

        <!-- FOOTER -->
        <div v-if="!noFooter" class="modal-footer">
          <slot name="footer">
            <button class="btn btn-secondary" @click="hideModal">Închideți</button>
            <button class="btn btn-primary" @click="save">Salvați</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { Modal } from "bootstrap";

import { useInitialTourStore } from "@/stores/initial-tour.js";
import { storeToRefs } from "pinia";

export default {
  name: "BaseModal",
  props: {
    // when used with v-if, set to true
    isLazy: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    centered: { type: Boolean, default: false },
    bodyClass: {
      type: String,
      default: "",
    },
    preventCloseKeyboard: {
      type: Boolean,
      default: false,
    },
    preventOutsideClickClose: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close", "save"],
  setup(props, context) {
    const modalRef = ref(null);
    const modalEl = ref(null);
    const modal = ref(null);

    onMounted(() => {
      modalEl.value = modalRef.value;
      modal.value = new Modal(modalEl.value);

      modalEl.value.addEventListener("hidden.bs.modal", () => {
        close();
      });

      if (props.isLazy) showModal();
    });

    onBeforeUnmount(() => {
      // NOTE: better use hideModal() function from parent component for lazy too, otherwise animation won't work..
      if (props.isLazy) hideModal();
    });

    function showModal() {
      modal.value.show();
    }
    function hideModal() {
      modal.value.hide();
    }

    function close() {
      context.emit("close");
    }
    function save() {
      context.emit("save");
    }

    const initialTourStore = useInitialTourStore();
    const { initialTour } = storeToRefs(initialTourStore);

    return { modalRef, showModal, hideModal, save, initialTour };
  },
};
</script>
<style lang="scss" scoped>
.modal-content {
  border-radius: 1.25rem;
}
</style>
