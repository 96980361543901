const multipleAgendasViews = ["multipleAgendaTableView", "listView"];
const agendaViews = ["listView", "dayGridMonth", "timeGridWeek", "timeGridDay"];

export function checkIfIsValidMultipleAgendasView(view: string) {
  return multipleAgendasViews.includes(view);
}

export function checkIfIsValidAgendaView(view: string) {
  return agendaViews.includes(view);
}
